.about-page {
  padding: 20px;
}

.about-section {
  margin-bottom: 40px;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.about-content-right {
  /* flex-direction: row-reverse; */
}

.about-image {
  width: 100%;
  max-width: 300px; /* Ensures image doesn't exceed this width */
  height: 200px; /* Specify a fixed height to maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  border-radius: 8px;
  margin: 0 20px;
}

.about-text {
  flex: 1;
  min-width: 200px;
  margin: 20px;
  text-align: left;
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .about-image {
    margin-bottom: 10px;
    max-width: 90%; /* Adjust image width on smaller screens */
    height: auto; /* Allow the height to scale proportionally */
  }
}
