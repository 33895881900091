.projects-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.projects-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #000;
}

.projects-subtitle {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #333;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.project-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-image {
  width: 100%;
  height: 200px; /* Set a fixed height for images */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  display: block;
}

.project-details {
  padding: 20px;
  text-align: center;
}

.project-details h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000;
}

.project-details p {
  font-size: 1rem;
  color: #555;
}

/* Ensures links have no underline and are black */
.project-card a {
  text-decoration: none; /* Remove underline */
  color: #000; /* Set link color to black */
}

.project-card a:hover {
  color: #000; /* Keep hover color black */
}

@media (max-width: 768px) {
  .project-image {
    height: 150px; /* Adjust image height on smaller screens */
  }
}
