.footer {
  display: flex;
  justify-content: space-between; /* Align text and icon on opposite sides */
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa; /* Light background color */
  border-top: 1px solid #ddd; /* Optional border */
  position: fixed; /* Keep footer at the bottom */
  bottom: 0; /* Align at the bottom */
  left: 0;
  right: 0;
  max-width: 100%; /* Prevent overflow */
  z-index: 10;
}

/* Hide footer on mobile devices (max-width: 768px) */
@media (max-width: 768px) {
  .footer {
    display: none !important; /* Hide footer on mobile devices */
  }
}

.footer p {
  margin: 0;
  font-size: 14px;
  color: #333; /* Adjust text color as needed */
}

.mail-link {
  text-decoration: none;
  color: #74707075;
  font-size: 1.2rem; /* Adjust icon size */
}

.mail-link:hover {
  color: #646c74; /* Optional hover color */
}
